import React, { useState } from 'react';
import { Camera, Menu, X, Instagram, Mail, Phone } from 'lucide-react';
import './PhotographyPortfolio.css';

const PhotographyPortfolio = () => {
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const categories = ['all', 'nature', 'portrait', 'street', 'architecture'];

    const photos = [
        { id: 1, category: 'nature', title: 'Mountain Vista', description: 'Sunset over mountain range' },
        { id: 2, category: 'portrait', title: 'Urban Portrait', description: 'Environmental portrait in city' },
        { id: 3, category: 'street', title: 'City Life', description: 'Candid street moment' },
        { id: 4, category: 'architecture', title: 'Modern Lines', description: 'Contemporary building study' },
        { id: 5, category: 'nature', title: 'Forest Path', description: 'Mystical woodland scene' },
        { id: 6, category: 'portrait', title: 'Natural Light', description: 'Studio quality natural lighting' }
    ];

    const filteredPhotos = selectedCategory === 'all'
        ? photos
        : photos.filter(photo => photo.category === selectedCategory);

    return (
        <div className="app">
            <nav className="navbar">
                <div className="nav-container">
                    <div className="logo">
                        <Camera className="logo-icon" />
                        <span className="logo-text">Photo Studio</span>
                    </div>

                    <div className="desktop-menu">
                        {categories.map(category => (
                            <button
                                key={category}
                                onClick={() => setSelectedCategory(category)}
                                className={`menu-item ${selectedCategory === category ? 'active' : ''}`}
                            >
                                {category}
                            </button>
                        ))}
                    </div>

                    <button
                        className="mobile-menu-button"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        {isMenuOpen ? <X /> : <Menu />}
                    </button>
                </div>

                {isMenuOpen && (
                    <div className="mobile-menu">
                        {categories.map(category => (
                            <button
                                key={category}
                                onClick={() => {
                                    setSelectedCategory(category);
                                    setIsMenuOpen(false);
                                }}
                                className="mobile-menu-item"
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                )}
            </nav>

            <header className="hero">
                <h1>Capturing Moments</h1>
                <p>Professional photography for every occasion</p>
            </header>

            <main className="gallery">
                <div className="gallery-grid">
                    {filteredPhotos.map(photo => (
                        <div
                            key={photo.id}
                            onClick={() => setSelectedImage(photo)}
                            className="gallery-item"
                        >
                            <img
                                src={`/api/placeholder/800/600`}
                                alt={photo.title}
                            />
                            <div className="overlay">
                                <div className="photo-info">
                                    <h3>{photo.title}</h3>
                                    <p>{photo.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </main>

            {selectedImage && (
                <div className="modal" onClick={() => setSelectedImage(null)}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <img
                            src={`/api/placeholder/1200/800`}
                            alt={selectedImage.title}
                        />
                        <div className="modal-info">
                            <h3>{selectedImage.title}</h3>
                            <p>{selectedImage.description}</p>
                        </div>
                    </div>
                </div>
            )}

            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-section">
                        <h3>Contact</h3>
                        <div className="contact-info">
                            <div className="contact-item">
                                <Mail />
                                <span>contact@photostudio.com</span>
                            </div>
                            <div className="contact-item">
                                <Phone />
                                <span>+1 (555) 123-4567</span>
                            </div>
                        </div>
                    </div>

                    <div className="footer-section">
                        <h3>Follow Us</h3>
                        <div className="social-links">
                            <Instagram className="social-icon" />
                        </div>
                    </div>

                    <div className="footer-section">
                        <h3>Studio Hours</h3>
                        <div className="hours-info">
                            <p>Monday - Friday: 9AM - 6PM</p>
                            <p>Saturday: 10AM - 4PM</p>
                            <p>Sunday: Closed</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default PhotographyPortfolio;
